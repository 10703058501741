<template>
  <div class="home">
    <headerTop v-if="readmeCont" title="积分商城" readme="兑换说明" @handleReadme="handleilltate" />
    <div
      class="shop_center"
      @click.stop="
        istype = false;
        iscategory = false;
        playAudio();
      "
    >
      <div
        class="shop_center_left"
        @click.stop="
          istype = !istype;
          playAudio();
        "
      >
        <div>
          {{ type }}
          <i class="el-icon-arrow-down" style="font-weight: bold"></i>
        </div>
        <div class="center_left_xl" v-if="istype">
          <div class="xl_btn" v-for="item in priceTypes" :key="item.type" :class="{ xl_active: type_s == item.type }" @click.stop="handleType(item)">{{ item.name }}</div>
        </div>
      </div>
      <div class="shop_center_right">
        <input type="text" v-model="queryParams.name" style="flex: 1; padding: 0 4px; height: 100%" placeholder="请输入饰品名称" />
        <i @click="handleSearch" class="icon el-icon-search"></i>
      </div>
      <div
        class="shop_center_pz_lb"
        @click.stop="
          istype = false;
          iscategory = false;
          playAudio();
        "
      >
        <div
          class="pz"
          @click.stop="
            iscategory = !iscategory;
            playAudio();
          "
        >
          <div>
            {{ category }}
            <i class="el-icon-arrow-down" style="font-weight: bold"></i>
          </div>
          <div class="pz_qt" v-if="iscategory">
            <div class="center_left_xl" v-for="(item, index) in categoryList" :key="index">
              <div class="xl_btn" :class="queryParams.category == Object.values(item)[0] ? 'xl_active' : ''" @click.stop="handleCategory(item)">
                {{ Object.keys(item)[0] }}
              </div>
            </div>
          </div>
        </div>
        <div
          style="display: flex"
          @click="
            isWorth = true;
            playAudio();
          "
          class="screening center"
        >
          <i class="el-icon-sort"></i>
          价值筛选
        </div>
      </div>
    </div>
    <div
      class="shop_bottom"
      ref="shop_bottom"
      @scroll="scrollEventFn"
      @click.stop="
        istype = false;
        iscategory = false;
        playAudio();
      "
    >
      <div v-for="(item, index) in shoplist" :key="index" @click="handleOpen(item)" class="weapon">
        <div class="weapon_img">
          <img :src="item.imageUrl" alt />
        </div>
        <div class="weapon_name">{{ item.name }}</div>
        <div class="integral-integral" style="margin-top: 4px">
          <price :type="2" :price="item.creditsPrice" size="middle" />
        </div>
      </div>
    </div>
    <div class="not_data pc-show" v-if="!shoplist.length">--暂无数据--</div>
    <!-- <div class="over_text mobile-show">{{ isOver }}</div> -->
    <!-- <div class="pagination pc-show"> -->
    <div class="pagination pc-show">
      <el-pagination :pager-count="5" background layout="prev, pager, next" :hide-on-single-page="true" :total="shopTotal" :current-page="queryParams.pageNum" :page-size="queryParams.pageSize" @current-change="getShoplist" />
    </div>
    <div class="pagination2 mobile-show">
      <el-pagination :pager-count="5" background layout="prev, pager, next" :hide-on-single-page="true" :total="shopTotal" :current-page="queryParams.pageNum" :page-size="queryParams.pageSize" @current-change="getShoplist" />
    </div>
    <alert class="exchange_alert" :visible="isOpen" title="积分兑换" @cancel="handleClose" @confirm="handleExchange" confirmText="兑换">
      <div class="exchange">
        <div class="exchange_title">{{ curExchange?.exteriorName }}</div>
        <div class="exchange_img">
          <img :src="curExchange?.imageUrl" alt="" width="100%" />
        </div>
      </div>
      <div class="textover-f">{{ curExchange?.name }}</div>
      <div class="item_integral center">
        <price :type="2" :price="curExchange?.usePrice" size="middle" />
      </div>
    </alert>
    <alert class="price_alert" :visible="isWorth" title="价值筛选" @cancel="handleWorth(1)" @confirm="handleWorth(2)">
      <input type="text" v-model="queryParams.minPrice" placeholder="请输入最小值" />
      <div style="border-top: 1px solid #ccc; width: 12px; margin: 0 4px"></div>
      <input type="text" v-model="queryParams.maxPrice" placeholder="请输入最大值" />
    </alert>
  </div>
</template>

<script>
import { shoplist, shopconvert, shopcondition, getArticle, getUserInfo } from '@/api/index';
export default {
  data() {
    return {
      isOver: '加载中',
      queryParams: {
        sortBy: null,
        maxPrice: null,
        minPrice: null,
        type: null,
        pageSize: 15,
        pageNum: 1,
      },
      iscategory: false,
      isWorth: false,
      isPrice: true,
      categoryList: [],
      category: '类别',
      curExchange: {},
      isOpen: false,
      pz_s: false,
      type: '综合排序',
      type_s: 'zhpx',
      istype: false,
      shopTotal: 0,
      shoplist: [],
      event: null,
      readmeCont: '',
      priceTypes: [
        { name: '综合排序', type: 'zhpx', sortBy: null },
        { name: '价格降序', type: 'jgjx', sortBy: 2 },
        { name: '价格升序', type: 'jgsx', sortBy: 1 },
      ],
    };
  },
  created() {
    this.getShoplist();
    this.getReadmeCont();
    this.getshopcondition();
  },
  methods: {
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleSearch() {
      this.getShoplist();
      this.playAudio();
    },
    getReadmeCont() {
      getArticle('shop').then(res => {
        let dream = res?.data?.data?.contentList?.[0].content;
        this.readmeCont = dream || '';
      });
    },
    handleilltate() {
      if (!this.readmeCont) return;
      this.playAudio();
      this.$bus.$emit('is_illustrate', true);
      this.$bus.$emit('nr_illustrate', { cont: this.readmeCont });
    },
    scrollEventFn(e) {
      this.event = e;

      if (e.srcElement.scrollTop + e.srcElement.clientHeight >= e.srcElement.scrollHeight-1) {
        if (this.isOver == '上拉加载更多') {
          this.queryParams.pageNum++;
          let timer = setTimeout(() => {
            this.getShoplist();
            clearTimeout(timer);
          }, 5);
        }
      }
    },
    handleWorth(res) {
      this.playAudio();
      if (res == 2) {
        this.isWorth = false;
        this.getShoplist();
      } else if (res == 1) {
        this.isWorth = false;
        this.queryParams.maxPrice = null;
        this.queryParams.minPrice = null;
      }
    },
    handleExchange() {
      this.playAudio();
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      let obj = { ornamentsId: this.curExchange.id };
      shopconvert(obj).then(res => {
        getUserInfo().then(res => {
          this.$store.commit('USER_INFO', res?.data?.data || {});
        });
        if (res?.data?.code == 200) {
          this.isOpen = false;
          this.$message({
            offset: 50,
            message: '兑换成功',
            type: 'success',
          });
        } else {
          this.$message({
            offset: 50,
            message: res.data.msg,
            type: 'warning',
          });
        }
      });
    },
    handleClose() {
      this.playAudio();
      this.isOpen = false;
    },
    handleOpen(res) {
      this.playAudio();
      this.isOpen = true;
      this.curExchange = res || {};
    },
    handleCategory(res) {
      this.queryParams.pageNum = 1;
      if (this.event) this.event.srcElement.scrollTop = 0;
      this.queryParams.type = Object.values(res)[0];
      this.getShoplist();
      this.iscategory = false;
      this.category = Object.keys(res)[0];
      if (Object.keys(res)[0] == '默认') {
        this.category = '类别';
      } else {
        this.category = Object.keys(res)[0];
      }
    },
    handleType(item) {
      this.playAudio();
      this.type = item.name;
      this.type_s = item.type;
      this.queryParams.sortBy = item.sortBy;
      this.istype = false;
      this.queryParams.pageNum = 1;
      this.getShoplist();
    },
    getShoplist(page) {
      if (page) this.queryParams.pageNum = page;
      shoplist(this.queryParams).then(res => {
        if (res?.data?.code != 200) return;

        const { list, total } = res?.data?.data || {};
        this.shoplist = [...list];
        this.shopTotal = total || 0;

        if (this.$store.state.mode != 'pc') {
          this.$nextTick(() => {
            let shop_bottom = this.$refs.shop_bottom;
            console.log(shop_bottom);

            shop_bottom.scrollTo({ top: 0, behavior: 'smooth' });
          });
        }
      });
    },
    getshopcondition() {
      shopcondition(0).then(res => {
        this.categoryList = res?.data?.data || [];
        this.categoryList.push({ 默认: '' });
      });
    },
  },
  watch: {
    istype(newValue) {
      if (newValue) {
        this.iscategory = false;
      }
    },
    iscategory(newValue) {
      if (newValue) {
        this.istype = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.screening {
  font-size: 16px;
  white-space: nowrap;
  @media @max750 {
    font-size: 14px;
  }
  .icon {
    width: 18px;
    height: 21px;
    padding-right: 2px;
    @media @max750 {
      width: 13px;
      height: 15px;
    }
  }
}
.pagination {
  .flex-center;

  width: 80%;
  margin: 20px auto 10px;
}
.pagination2 {
  .flex-center;
  width: 90%;
  margin: 20px auto 10px;
  position: fixed;
  bottom: 60px;
}
.price_alert {
  ::v-deep .alert_content {
    .flex-center;
    input {
      width: 45%;
      height: 56px;
      .sc(18px, #fff);
      text-align: center;
      border-radius: 35px;
      background-color: #323436;
      @media @max750 {
        height: 40px;
        font-size: 14px;
      }
    }
  }
}

.shop_center_pz_lb {
  margin: 0 auto;
  display: flex;
  align-items: center;
  .pz {
    width: 130px;
    height: 42px;
    background: #323436;
    border-radius: 70px;
    opacity: 1;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 20px;
    @media @max750 {
      width: 70px;
      height: 30px;
      font-size: 11px;
      margin-right: 10px;
    }

    .pz_qt {
      position: absolute;
      top: 49px;
      width: 100%;
      background: #101115;
      border-radius: 14px;
      opacity: 1;
      padding: 1px;
      @media @max750 {
        top: 39px;
      }
      .xl_btn {
        width: 100%;
        height: 42px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        @media @max750 {
          height: 30px;
        }
      }

      .xl_active {
        color: @main;
      }
    }
  }
  .lb {
    width: 112px;
    height: 42px;
    background: #323436;
    border-radius: 70px 70px 70px 70px;
    opacity: 1;
    position: relative;
  }
}

.exchange_alert {
  ::v-deep .alert_content {
    .exchange {
      width: 200px;
      border-radius: 13px;
      background: rgba(@main, 0.25);
      background-size: 100% 100%;
      border: 1px solid @main;
      margin: 20px auto;
      padding: 10px;
      @media @max750 {
        width: 150px;
        padding-top: 9px;
        font-size: 12px;
      }
      .exchange_title {
        text-align: center;
        @media @max750 {
          font-size: 12px;
        }
      }
      .exchange_img {
        width: 144px;
        height: 144px;
        margin: 0 auto;
        .flex-center;
        @media @max750 {
          width: 103px;
          height: 103px;
        }
      }
    }
  }
}
.exchange_bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
  .flex-center;
  .exchange_bg_item {
    width: 30%;
    padding: 32px;
    margin: 0 auto;
    background: @bg1;
    border-radius: 20px;
    position: relative;
    box-sizing: border-box;
    opacity: 1;
    @media @max750 {
      width: 90%;
      padding: 23px;
    }
    .exchange_bg_item_title {
      font-size: 28px;
      @media @max750 {
        font-size: 20px;
      }
    }

    .exchange {
      width: 200px;
      background: rgba(194, 61, 212, 0.25);
      border-radius: 13px;
      background-size: 100% 100%;
      opacity: 1;
      border: 1px solid #c23dd4;
      margin: 20px auto;
      padding: 10px;
      @media @max750 {
        width: 150px;
        padding-top: 9px;
        font-size: 12px;
      }
      .exchange_title {
        text-align: center;
        @media @max750 {
          font-size: 13px;
        }
      }
      .exchange_img {
        width: 144px;
        height: 144px;
        margin: 0 auto;
        .flex-center;
        @media @max750 {
          width: 103px;
          height: 103px;
        }
      }
    }
    .item_integral {
      margin: 14px 0;
      padding: 10px 20px;
      background: #323436;
      border-radius: 25px;
      opacity: 1;
      @media @max750 {
        margin-top: 10px;
      }
    }
  }
}
.shop_bottom {
  .grid(5, 10px);
  margin-top: 30px;
  @media @max750 {
    .grid(2, 10px);
    margin: 0 auto;
    height: 580px;
    margin-top: 14px;
    overflow-y: auto;
    padding-bottom: 50px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .weapon {
    padding: 10px 0;
    .flex-column-center;
    background: #101115;
    background-size: 100% 100%;
    border-radius: 14px 14px 14px 14px;
    @media @max750 {
      height: 125px;
      font-size: 12px;
    }
    .weapon_img {
      width: 100%;
      height: 108px;
      .flex-center;
      @media @max750 {
        height: 83px;
      }
      img {
        .wh(auto, 100%);
      }
    }
    .weapon_name {
      .ell2;
      width: 90%;
      margin: 0 auto;
      text-align: center;
      .sc(18px, #fff);
      @media @max750 {
        font-size: 14px;
      }
    }
  }
}

.shop_center {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .shop_center_left {
    width: 140px;
    height: 42px;
    background: #323436;
    border-radius: 70px 70px 70px 70px;
    opacity: 1;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media @max750 {
      width: 90px;
      height: 30px;
      font-size: 11px;
    }

    .center_left_xl {
      position: absolute;
      width: 100%;
      background: #101115;
      opacity: 1;
      top: 47px;
      animation: xl 0.2s linear;
      overflow: hidden;
      z-index: 10;
      @media @max750 {
        top: 37px;
        animation: xlsj 0.2s linear;
      }

      .xl_btn {
        width: 100%;
        height: 42px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        @media @max750 {
          height: 30px;
        }
      }

      .xl_active {
        color: @main;
      }
    }
  }

  .shop_center_right {
    flex: 1;
    height: 42px;
    margin: 0 20px;
    padding: 0 20px;
    overflow: hidden;
    background: #323436;
    font-size: 16px;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media @max750 {
      height: 30px;
      font-size: 12px;
      margin: 0 10px;
      padding: 0 6px;
    }

    input {
      width: 100%;
      height: 100%;
      border-radius: 35px;
      border: 1px solid #323436;
      color: #fff;
      font-size: 14px;
      background-color: #323436;
      outline: none;
      @media @max750 {
        font-size: 11px;
      }
    }
    .icon {
      padding: 5px;
      font-size: 20px;
      @media @max750 {
        font-size: 14px;
      }
    }
  }
}

@keyframes xlsj {
  0% {
    height: 0;
  }

  50% {
    height: 40px;
  }

  100% {
    height: 90px;
  }
}
@keyframes xl {
  0% {
    height: 0;
  }

  50% {
    height: 70px;
    @media @max750 {
      height: 40px;
    }
  }

  100% {
    height: 140px;
    @media @max750 {
      height: 90px;
    }
  }
}

.home {
  margin: 24px @pcSpace 0;
  @media @max750 {
    margin: 14px @mSpace 0;
  }
}
</style>
